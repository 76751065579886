import { Route, Routes, Navigate } from "react-router-dom";
import LandingPage from "./pages/landingPage";
import "bootstrap/dist/css/bootstrap.css";

const App = () => {
  return (
    <div className="dark">
      <Routes>
        <Route path="/" element={<LandingPage />} />

        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

export default App;
